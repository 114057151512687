import React from 'react';
import { Form, Button, TreeSelect, Popover, Row, Badge } from 'antd';
const FormFilter = ({
  type,
  filterOptions,
  dvirFilterOptions,
  nextPMFilterOptions,
  onSubmit,
  onClear,
  popoverVisible,
  togglePopoverVisibility,
  countFiltersSelected,
  form,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);

        onSubmit(values);
      }
    });
  };

  const clearData = () => {
    form.resetFields();
    onClear();
  };

  return (
    <Popover
      placement={'bottomLeft'}
      visible={popoverVisible}
      onVisibleChange={togglePopoverVisibility}
      content={
        <Form style={{ width: '78vw' }} onSubmit={handleSubmit}>
          {type === 'vehicle' && (
            <Form.Item label={'Filter by Status'}>
              {form.getFieldDecorator('car_status')(
                <TreeSelect
                  treeData={filterOptions.map((item) => ({
                    title: item.text,
                    value: item.value,
                  }))}
                  allowClear={true}
                  treeCheckable={true}
                  showCheckedStrategy={TreeSelect.SHOW_PARENT}
                  searchPlaceholder={'Please select status'}
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          )}

          <Form.Item label={'Filter by DVIR'}>
            {form.getFieldDecorator('dvirStatus')(
              <TreeSelect
                treeData={dvirFilterOptions.map((item) => ({
                  title: item.text,
                  value: item.value,
                }))}
                allowClear={true}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                searchPlaceholder={'Please select DVIR'}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Form.Item label={'Filter by Next PM due'}>
            {form.getFieldDecorator('nextPmFilter')(
              <TreeSelect
                treeData={nextPMFilterOptions.map((item) => ({
                  title: item.text,
                  value: item.value,
                }))}
                allowClear={true}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                searchPlaceholder={'Please select Next PM due'}
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Form.Item>
            <Row type="flex" justify="end" align="middle" gutter={[16, 16]}>
              <Button type="ghost" htmlType="reset" onClick={clearData}>
                Clear
              </Button>
              <Button type="primary" htmlType="submit">
                Apply
              </Button>
            </Row>
          </Form.Item>
        </Form>
      }
      title={'Filters'}
      trigger={'click'}
    >
      <Button type="primary" icon={'filter'} block>
        Filters{' '}
        {countFiltersSelected > 0 ? (
          <>
            <Badge
              count={countFiltersSelected >= 10 ? '9+' : countFiltersSelected}
              style={{
                marginLeft: 5,
                backgroundColor: '#fff',
                color: '#123FBF',
              }}
            />
          </>
        ) : (
          ''
        )}
      </Button>
    </Popover>
  );
};

export default Form.create()(FormFilter);
